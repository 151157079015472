@font-face {
	font-family: 'Iosevka';
	src: local('Iosevka'), url('./fonts/iosevka-custom-bold.woff2') format('woff2');
	font-weight: 700;
}

@font-face {
	font-family: 'Iosevka';
	src: local('Iosevka'), url('./fonts/iosevka-custom-medium.woff2') format('woff2');
	font-weight: 500;
}

@font-face {
	font-family: 'Iosevka';
	src: local('Iosevka'), url('./fonts/iosevka-custom-regular.woff2') format('woff2');
	font-weight: 400;
}

@font-face {
	font-family: 'Iosevka';
	src: local('Iosevka'), url('./fonts/iosevka-light.woff2') format('woff2');
	font-weight: 300;
}

* {
	font-family: 'Iosevka';
	-webkit-font-smoothing: antialiased;
}
